<div class="overlay-container" *ngIf="overlay" >
  <div class="content-container">
    <div class="close-button" (click)="overlay=null">
      <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
        <path d="M28.864 16.136a1.5 1.5 0 0 1 0 2.121L24.622 22.5l3.742 3.744a1.5 1.5 0 0 1-2.121 2.121L22.5 24.621l-4.244 4.243a1.5 1.5 0 0 1-2.121-2.121l4.243-4.244-4.743-4.742a1.5 1.5 0 0 1 2.121-2.121l4.744 4.742 4.242-4.242a1.5 1.5 0 0 1 2.121 0z"/>
      </svg>
    </div>
    <div [ngSwitch]="overlay.overlayType">

      <div *ngSwitchCase="'promise'" >
        <div class="scroll-container">
          <div [innerHTML]="overlayContent"></div>
        </div>
      </div>


      <div *ngSwitchCase="'terms'" >
        <div class="scroll-container">
          <h4 class="text-title">SharePeople b.v. Algemene Voorwaarden </h4>
          <div class="text-body">
            <h4>Algemeen</h4>
            <section>
              <h6>1)</h6>
              <p>
                Deze Algemene Voorwaarden zijn van toepassing, zodra de besloten SharePeople B.V.
                en/of haar werkmaatschappij of bestuurders en medewerkers, communiceren en
                faciliterend een relatie (zullen) aangaan. Alle bepalingen van deze voorwaarden zijn van
                kracht, tenzij en voor zover hier schriftelijk uitdrukkelijk van is afgeweken.
                Strekking
              </p>
            </section>
            <section>
              <h6>2)</h6>
              <p>
                a) De werkzaamheden van SharePeople betreffen het begeleiden van deelnemers in hun
                onderlinge contacten en het doen van donaties aan elkaar.
                <br>
                b) De relatie van SharePeople met wie eenmaal op basis van de onderhavige voorwaarden
                werd gecommuniceerd wordt geacht stilzwijgend akkoord te zijn gegaan met de
                toepasselijkheid van deze voorwaarden op later met SharePeople gesloten en of niet
                gesloten en of beëindigde overeenkomsten.
                Totstandkoming
              </p>
            </section>
            <section>
              <h6>3)</h6>
              <p>
                Een overeenkomst met een deelnemer komt tot stand op het moment dat SharePeople dit
                schriftelijk verklaart, dat kan ook per e-mail of andersoortig dataverkeer. De in voorgaande
                zin opgenomen regel geldt eveneens voor wijzigingen in overeenkomsten.
                Rechten en verplichtingen SharePeople
              <p>
            </section>
            <section>
              <h6>4)</h6>
              <p>
                a) SharePeople is gehouden de belangen van anderen waarmee zij een relatie aangaat
                naar beste weten en kunnen te behartigen, één en ander voor zover zulks, gelet op de
                rechtsverhouding en de daaruit voortvloeiende faciliterende werkzaamheden, mogelijk en
                wenselijk is.
                <br>
                b) Een resultaat van het faciliteren wordt niet gegarandeerd. Of het beoogde resultaat (het
                voorkomen van arbeidsongeschiktheid en het verzachten van de financiële gevolgen van
                arbeidsongeschiktheid) daadwerkelijk wordt bereikt is afhankelijk van tal van factoren.
                <br>
                c) Daar
                bij heeft SharePeople het recht om -in overleg met en na verkregen toestemming
                van de relatie/deelnemer van het platform -informatie met anderen te delen en bepaalde
                werkzaamheden te laten uitvoeren door derden of zich te laten bijstaan door derden.
                Weigert de deelnemer die informatie te verschaffen dan kan SharePeople haar
                werkzaamheden niet uitvoeren.
                Verplichtingen relatie, met name maar niet uitsluitend de deelnemers
              </p>
            </section>
            <section>
              <h6>5)</h6>
              <p>
                a) Op relaties en met name deelnemers rust de verplichting aan SharePeople tijdig,
                volledig en op de juiste wijze gegevens, inlichtingen en bescheiden te verstrekken welke
                benodigd zijn voor uitvoering van de faciliterende werkzaamheden.
                <br>
                b) Indien en voor zover tekortkomingen bij de uitvoering van de werkzaamheden verricht
                door SharePeople het gevolg zijn van niet, niet tijdig of niet in voldoende mate voldoen aan
                de op de relatie rustende (informatie) verplichting, is SharePeople daarvoor niet
                aansprakelijk.
                Vertrouwelijk

              </p>
            </section>
            <section>
              <h6>6)</h6>
              <p>
                Geheimhouding
                SharePeople verplicht zich alle gegevens en stukken, die betrekking hebben op de
                deelnemer(s), welke in redelijkheid hebben te gelden als vertrouwelijk en geheim, als
                zodanig te behandelen. SharePeople draagt er zorg voor -ook middels deze voorwaardendat deze verplichting ook wordt opgelegd aan eventuele relaties die door haar bij een
                opdracht worden ingeschakeld.
                Beëindiging
              </p>
            </section>
            <section>
              <h6>7)</h6>
              <p>
                a) Een deelnemer kan te allen tijde deelname beëindigen. SharePeople kan haar
                faciliterende werkzaamheden alleen beëindigen nadat een redelijke opzegtermijn in acht is
                genomen. Relaties die door SharePeople zijn ingeschakeld ten behoeve van deelnemers
                kunnen hun werkzaamheden niet dan na schriftelijke toestemming van de deelnemer
                beëindigen, omdat het hier gaat om begeleiding voor een beperkte periode van maximaal
                twee jaar bij arbeidsongeschiktheid, tenzij sprake is van onvoorziene omstandigheden of
                belangrijk gewijzigde omstandigheden.
                <br>
                b) Na het beëindigen van de relatie zullen alle data worden vernietigd dan wel tegen
                betaling in een zodanige staat terug worden gegeven dat de deelnemers c.q. de door
                SharePeople aan te wijzen derde, zonder schade voor de voortgang van die
                werkzaamheden, op aanvaardbare wijze verder uitvoerbaar is, met in achtneming van de in
                artikel 5 van deze voorwaarden bepaalde.
                <br>
                b) Een ieder kan een relatie met onmiddellijke ingang beëindigen indien de ander in
                surseance van betaling komt te verkeren of in staat van faillissement (of WSNP) is
                verklaard.
                Bepalingen van financiële aard
              </p>
            </section>
            <section>
              <h6>8)</h6>
              <p>
                a) Indien partijen niet schriftelijk anders zijn overeengekomen, wordt een aan SharePeople
                toekomende vergoeding (gebruikskosten en inschrijfkosten) voor de werkzaamheden van
                SharePeople vastgesteld aan de hand van een verantwoording van gemaakte kosten.
                <br>
                b) Alle honoraria zijn exclusief B.T.W.
                <br>
                c) Het overeengekomen honorarium dient te worden voldaan binnen 14 dagen na
                dagtekening van de opdrachtbevestiging.
                <br>
                d) Blijft naar aanleiding van een verzoek te betalen een deugdelijke reactie op dit verzoek
                uit, dan zal SharePeople gerechtigd zijn de overeenkomst te ontbinden. Eén en ander
                onverminderd het recht op bepaling van honorarium en vanwege de ontbinding
                toekomende schadevergoeding.
                Bepalingen m.b.t. beperkte aansprakelijkheid
              </p>
            </section>
            <section>
              <h6>9)</h6>
              <p>
                a) SharePeople is tegenover deelnemers slechts aansprakelijk voor schade ten gevolg van
                een ernstige toerekenbare tekortkoming in de uitvoering van de werkzaamheden en
                tegenover andere relaties in beginsel in het geheel niet aansprakelijk omdat SharePeople
                slechts faciliteert en niet zelf uitvoert.
                <br>
                b) Indien een rechter SharePeople toch aansprakelijk zou oordelen voor gelede schade, dan
                is haar aansprakelijkheid beperkt tot het bedrag dat in het voorkomende geval wordt
                uitgekeerd krachtens de door SharePeople afgesloten
                beroepsaansprakelijkheidsverzekering of andere aansprakelijkheidsverzekering,
                vermeerderd met het voor SharePeople geldende eigen risico, waarbij het totaal van deze
                bedragen is beperkt tot het maximumbedrag van de verzekering.
                <br>
                c) Indien, om welke reden dan ook, geen verzekeringsuitkering plaatsvindt, is de
                aansprakelijkheid van SharePeople beperkt tot het honorarium van haar werkzaamheden
                direct gerelateerd aan de (nagelaten) werkzaamheden waarop de aansprakelijkheid
                betrekking heeft, met een maximum van € 2.000,-.
                <br>
                d) SharePeople is niet gehouden tot vergoeding van indirecte schade geleden door
                deelnemers of relaties, waaronder begrepen maar niet beperkt tot gevolgschade, gederfde
                winst en schade ten gevolge van arbeidsongeschiktheid.
                <br>
                e) SharePeople zal bij de inschakeling van niet in haar organisatie werkzame derden (zoals
                adviseurs, deskundigen of dienstverleners) de nodige zorgvuldigheid in acht nemen.
                SharePeople is niet aansprakelijk voor ernstige tekortkomingen jegens deelnemers of voor
                eventuele fouten of tekortkomingen van deze derden. In zo’n geval is de deelnemer
                verplicht de ingeschakelde derden zelf aansprakelijk te stellen en eventueel geleden schade
                op deze derden te verhalen.
                <br>
                f) SharePeople is niet aansprakelijk voor door deelnemers of relatie geleden schade, van
                welke aard ook, indien bij het aangaan van de relatie of de uitvoering van de
                werkzaamheden is uitgegaan van door deelnemer of relatie verstrekte onjuiste en/of
                onvolledige gegevens.
                <br>
                g) Relatie vrijwaart SharePeople tegen alle aanspraken (zoals schades en
                rechtsvorderingen) van deelnemers of derden die met de relatie tussen SharePeople, de
                deelnemer en/of de relaties samenhangen, tenzij het betreft aanspraken ten gevolge van
                ernstige tekortkomingen van SharePeople.
                <br>
                h) Indien een relatie of deelnemer een eventuele vordering jegens SharePeople niet binnen
                1 jaar na het ontdekken van de schade in rechts aanhangig heeft gemaakt, komt deze
                rechtsvordering na het verstrijken van het jaar te vervallen.
              </p>
            </section>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'privacy'" >
        <div class="scroll-container">
          <h4 class="text-title">Privacy</h4>
          <div class="text-body">
            <section>
              <p>
                Internet users can use the services on the SharePeople website ( www.sharepeople.nl ) free of charge.
                The fact that you use the SharePeople website means that you agree to the terms of use for the SharePeople website.
              </p>
            </section>
            <section>
              <h6>INTELLECTUAL PROPERTY RIGHTS</h6>
              <p>
                The intellectual property rights of software, texts, images and / or sounds on the SharePeople website are held by SharePeople. Duplication, distribution and publication of software, texts, images and / or sounds on the SharePeople website is only permitted with prior permission.
              </p>
              <p>
                You send written requests to SharePeople, contact @ sharepeople.
              </p>
            </section>
            <section>
              <h6>PRIVACY DECLARATION</h6>
              <p>
                SharePeople, located at Hoofdstraat 25, 3971 KA DRIEBERGEN, is responsible for the processing of personal data as shown in this privacy statement.
              </p>

              <p>Contact information:
                SharePeople BV
                Hoofdstraat 25
                3971 KA DRIEBERGEN
                0343 - 227 002
                www.sharepeople.nl</p>

              <p>Rutger Abbink is the Data Protection Officer of SharePeople. He can be reached via contact@sharepeople.nl .</p>

              <p>Personal data that we process</p>
              <p>SharePeople processes your personal data because you use our services and / or because you provide this data to us yourself.</p>

              <p>Below you will find an overview of the personal data that we process:</p>
              <ul>
                <li>First and last name</li>
                <li>Initials</li>
                <li>Gender</li>
                <li>Date of birth</li>
                <li>Address details</li>
                <li>Telephone number</li>
                <li>E-mail address and</li>
                <li>Bank account number</li>
                <li>Company name</li>
              </ul>
            </section>
            <section>
              <h6>Special and / or sensitive personal data that we process</h6>
              <p>
                Our website and services do not intend to collect data about website visitors under the age of 16. However, we cannot check whether a visitor is older than 16. If you are convinced that we have collected personal information about a minor, please contact us at contact@sharepeople.nl and we will remove this information.
              </p>
            </section>
            <section>
              <h6>
                For what purpose and on what basis we process personal data
              </h6>
              <p>SharePeople processes your personal data for the following purposes:</p>
              <ul>
                <li>Sending our newsletter</li>
                <li>Performing our services</li>
                <li>Being able to call or e-mail you if this is necessary to provide our services being able to conduct</li>
                <li>Inform you about changes to our services and products</li>
              </ul>
            </section>
            <section>
              <h6>Automated decision-making</h6>
              <p>
                SharePeople does not take decisions based on automated processing of cases that may have (significant) consequences for individuals. These are decisions that are made by computer programs or systems, without a human being (such as an employee of SharePeople) being involved.
              </p>
            </section>
            <section>
              <h6>How long we store personal data</h6>
              <p>
                SharePeople does not store your personal data for longer than is strictly necessary to achieve the purposes for which your data is collected. We use the following retention periods for the following (categories) of personal data:
              </p>
            </section>
            <section>
              <h6>Potential participants:</h6>
              <p>
                Name and email address are saved and updated as long as the potential participant does not unsubscribe.
                Participants:
                All data provided by the participant is stored and updated as long as the participant is registered as such with SharePeople. After participation, only the name and email address will be saved, as long as the former participant does not unsubscribe.
              </p>

              <h4>Sharing personal data with third parties</h4>
              <p>
                SharePeople only provides this to third parties and only if this is necessary for the performance of our services or to comply with a legal obligation.
              </p>
              <p>SharePeople will never provide personal data to third parties for commercial purposes.</p>
            </section>
            <section>
              <h6>Cookies, or similar techniques, that we use</h6>
              <p>
                SharePeople uses functional, analytical and tracking cookies. A cookie is a small text file that is stored in the browser of your computer, tablet or smartphone when you first visit this website. SharePeople uses cookies with purely technical functionality. These ensure that the website works properly and that, for example, your preferred settings are remembered. These cookies are also used to make the website work properly and to optimize it. In addition, we place cookies that track your surfing behavior so that we can offer customized content and advertisements.
              </p>
              <p>
                On your first visit to our website, we have already informed you about these cookies and have requested your permission to place them. You can opt out of cookies by setting your internet browser so that it no longer stores cookies. In addition, you can also delete all information that was previously saved via the settings of your browser. Cookies are also placed on this website by third parties. These are, for example, advertisers and / or social media companies. Below an overview:
              </p>
              <p>
                Cookie: Google Analytics
                Function: Analytical cookie that measures website visits. Retention
                period: 2 years
              </p>
            </section>
            <section>
              <h6>Viewing, adjusting or deleting data</h6>
              <p>
                You have the right to view, correct or delete your personal data. In addition, you have the right to withdraw your consent for data processing or to object to the processing of your personal data by SharePeople and you have the right to data transferability. This means that you can submit a request to us to send your personal data that we have in a computer file to you or another organization mentioned by you.
              </p>

              <p>
                You can send a request for access, correction, removal, data transfer of your personal data or request for withdrawal of your consent or objection to the processing of your personal data to contact@sharepeople.nl.
              </p>

              <p>
                To ensure that the request for inspection has been made by you, we request that you send a copy of your proof of identity with the request. Make in this copy your passport photo, MRZ (machine readable zone, the strip with numbers at the bottom of the passport), passport number and Citizen service number (BSN) black. This is to protect your privacy. We will respond to your request as quickly as possible, but within four weeks.
              </p>

              <p>
                SharePeople would also like to point out that you have the option to file a complaint with the national data protection authority, the Dutch Data Protection Authority. You can do this via the following link: https : //autoriteit Individual data.nl/nl/contact-with-de-autoriteit- Individual data/tip-ons
              </p>
            </section>
            <section>
                <h6>
                  How we protect personal data
                </h6>
                <p>
                  SharePeople takes the protection of your data seriously and takes appropriate measures to prevent abuse, loss, unauthorized access, unwanted disclosure and unauthorized changes. If you feel that your data is not properly protected or there are indications of abuse, please contact our customer service or via contact@sharepeople.nl
                </p>
              </section>
            <section>
            <h6>
              DISCLAIMER OF LIABILITY
            </h6>
            <p>
              Despite constant care and attention for the SharePeople website, SharePeople does not guarantee the completeness, accuracy or topicality of the content of the SharePeople website.
            </p>
            <p>
              SharePeople can never be held liable for direct or indirect damage by using the SharePeople website, the inability to use the SharePeople website, from the delivery of services by SharePeople or the lack of delivery of services by SharePeople.
            </p>
          </section>
            <section>
                <h6>
                  AMENDMENTS
                </h6>
                <p>
                  The content of the SharePeople website is regularly updated. SharePeople reserves the right to change the content of the SharePeople website at any time and with immediate effect without prior notice.
                </p>
              </section>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>












