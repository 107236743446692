<!--IMPORTANT: content-grid container - the one contains tile is rendered based on user's status, so there are 4 instances of them. -->
<!--When making a change in tiles, it needs be done in all instances and tested with 4 different users since layout also changes per user status-->
<!--statuses:-->
<!-- - users without splong invitation code-->
<!-- - users with splong invitation code (not joined or rejected)-->
<!-- - users with sp-long rejected true-->
<!-- - users with sp-long joined true-->

<!--when SP long product will be available for all users, those conditions needs to be reviewed-->

<ng-container *ngIf="(memberData$ | async) as memberData; else loading">
  <ng-container *ngIf="(profileData$ | async) as profileData; else loading">
    <ng-container *ngIf="(updatedHistory$ | async) as updatedHistory; else loading">

      <section class="outstanding-donations">
        <sp-portal-make-donation
          [contributionDetails]="(memberData$ | async)?.contribution"
          [hasMissedContributions]="(memberData$ | async)?.contributionHistory?.hasMissedContributions"
          class="contribution-due">
        </sp-portal-make-donation>
      </section>

      <section class="donation-history">
        <div class="section-header">
          <h4>Donaties</h4>
          <span (click)="navigateTo('contribution-history')">bekijk alle</span>
        </div>
        <ng-container
          *ngIf="memberData.contributionHistory.history && memberData.contributionHistory.history.length > 0; else noDonations">
          <div class="horizontal-scroll">
            <sp-shared-lib-rounded-container shadow class="donation-tile-month average-donation">
              <div>
                <p class="amount">&#8364; {{memberData.contributionHistory.average}}</p>
                <p class="month">Gemiddeld per maand (sinds {{memberData.startDate}})</p>
              </div>
            </sp-shared-lib-rounded-container>
            <ng-container *ngFor="let donation of updatedHistory">
              <sp-shared-lib-rounded-container class="donation-tile-month"
                                               [ngClass]="donation.status === 'no-donation' ? 'no-donation' : ''"
                                               *ngIf="donation.period">
                <div>
                  <p class="amount">&#8364; {{donation.amount}}</p>
                  <p class="month">{{donation.period}}</p>
                  <p *ngIf="donation.status !== 'Niet gedaan'" class="receiver">{{donation.description}}</p>
                  <p *ngIf="donation.status === 'Niet gedaan'" class="not-paid"> {{donation.status}}</p>
                </div>
              </sp-shared-lib-rounded-container>
            </ng-container>
          </div>
        </ng-container>

        <ng-template #noDonations>
          <section class="no-contributions">
            <h4>Er zijn nog geen donaties</h4>
          </section>
        </ng-template>
      </section>

      <section class="account">
        <div class="section-header">
          <h4>Jouw account</h4>
        </div>

        <div class="content-grid">
          <sp-shared-lib-rounded-container class="your-account" style="order:1"  >
            <ng-container [ngTemplateOutlet]="yourAccount" [ngTemplateOutletContext]="{ memberData: memberData }"></ng-container>
          </sp-shared-lib-rounded-container>


          <sp-shared-lib-rounded-container  class="sp-aov-first-payment" *ngIf="memberData.aov.eligibleForAovFirstPayment"  style="order:2" >
            <div class="container-inner">
              <h4>SharePeople AOV</h4>
              <p>
                Betaal <a (click)="openAOVPayment()" target="_blank">hier</a> je eerste premie van {{memberData.aov.firstFee | currency:'EUR'}}.
              </p>
            </div>
          </sp-shared-lib-rounded-container>

          <sp-shared-lib-rounded-container class="invest-in-sharepeople" [ngStyle]="{'order': !memberData.aov.subscription ? 4 : 3}">
            <div class="first-part">
              <h4 class="subtitle">Wil je ook lid worden van de SharePeople Cooperatie?</h4>
              <p><a href="https://sharepeople.nl/cooperatie" target="_blank">Klik dan hier</a> voor meer informatie.
              </p>
              <p><a (click)="openPopup()">Klik vervolgens hier</a> om een account in Eyevestor aan te maken.</p>
            </div>
            <div class="second-part">
              <h4 class="subtitle">Investeringsronde 2023</h4>
            </div>
          </sp-shared-lib-rounded-container>

          <sp-shared-lib-rounded-container class="call-in-sick" [ngStyle]="{'order': !memberData.aov.subscription ? 3 : 4}">
            <ng-container [ngTemplateOutlet]="callInSick" [ngTemplateOutletContext]="{ memberData: memberData, profileData: profileData }"></ng-container>
          </sp-shared-lib-rounded-container>

            <sp-shared-lib-rounded-container class="sp-long-invitation" [ngStyle]="{'order': !memberData.aov.subscription ? 5 : 6}" *ngIf="memberData.canApplyForAov || memberData.spLongCoverage===50000"  >
              <div class="container-inner" >
                <h4>Voor jou: De SharePeople AOV</h4>
                <p>
                  Meld je nu aan voor onze groeps-AOV. Met dekking tot aan de AOW-leeftijd én een geldige opt-out voor de verplichte AOV.</p>
                <p><a (click)="startAovOnboarding()">Regel 't nu</a>.</p>
              </div>
            </sp-shared-lib-rounded-container>
          <sp-shared-lib-rounded-container  class="more-info" [ngStyle]="{'order': !memberData.aov.subscription ? 6 : 5}">
            <ng-container [ngTemplateOutlet]="moreInfo"></ng-container>
          </sp-shared-lib-rounded-container>
        </div>


      </section>

      <sp-portal-aov-terms-popup [AOVTermsPopupActive]="aovTermsPopup" [fee]="memberData.aov.monthlyFee" [paymentUrl]="memberData.aov.paymentUrl" (close)="aovTermsPopup =false"></sp-portal-aov-terms-popup>
      <sp-portal-long-warning-popup [longWarningPopupActive]="longWarningPopup" [spLongUrl]="linkToSpLong" (close)="longWarningPopup=false;pollMemberData()"></sp-portal-long-warning-popup>

    </ng-container>
  </ng-container>
</ng-container>

<div class="popup-background" [class.active]="eyevestorPopupActive">
  <div class="popup-container">
    <div class="flex-container">
      <div class="close-button" (click)="eyevestorPopupActive = false">
        <ng-container *ngTemplateOutlet="crossIcon"></ng-container>
      </div>
      <p *ngIf="popupLoading">Loading...</p>
      <p *ngIf="!popupLoading">{{popupText}}</p>
    </div>
  </div>
</div>

<sp-portal-occupation-popup></sp-portal-occupation-popup>
<!--<sp-portal-extended-coverage-popup [extendedCoverageOptions]="extendedCoverageOptions"></sp-portal-extended-coverage-popup>-->

<ng-template #loading>
  <sp-loading-spinner></sp-loading-spinner>
</ng-template>

<ng-template #crossIcon>
  <svg style="fill: #46b278" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492">
    <path
      d="M300 246L484 62a27 27 0 000-38L468 8a27 27 0 00-38 0L246 192 62 8a27 27 0 00-38 0L8 24a27 27 0 000 38l184 184L8 430a27 27 0 000 38l16 16a27 27 0 0038 0l184-184 184 184a27 27 0 0038 0l16-16a27 27 0 000-38L300 246z"/>
  </svg>
</ng-template>

<ng-template #checkmark>
  <svg style="fill: #46b278" xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10">
    <path
      d="M4.757 7.525L10.205.393a1 1 0 0 1 1.59 1.214l-6.111 8a1 1 0 0 1-1.47.131L.325 6.183a1 1 0 0 1 1.35-1.477l3.082 2.819z"/>
  </svg>
</ng-template>


<ng-template #callInSick let-memberData="memberData" let-profileData="profileData">
  <ng-container *ngIf="!memberData.reportedSick">
    <h4>Ziek melden</h4>
    <p>Ben je ziek en verwacht je dat dit langer dan een maand gaat duren?</p>
    <!-- WARNING: Please take note that sickness report is not yet implemented, until it is we should go to the external link -->
    <!--    <p><a [routerLink]="'report'" >Klik hier</a> om je ziek te melden.</p>-->
    <p><a
      href="https://www.sharepeople.nl/a15rgw35ws76f/?spid={{memberData.memberCode}}&voornaam={{profileData.firstName}}&achternaam={{profileData.lastName}}&email={{profileData.email}}&gebdd={{profileData.dob}}"
      target="_blank">Klik hier</a> om je ziek te melden.</p>
  </ng-container>
  <ng-container *ngIf="memberData.reportedSick">
    <h4>Ziek gemeld</h4>
    <p>Eerste ziektedag: {{memberData.reportedSick}}</p>
  </ng-container>
</ng-template>

<ng-template #yourAccount let-memberData="memberData">
  <div class="container-inner">
    <p class="accountStatus">Status: Actief</p>
    <p class="accountMemberSinceDate">Deelnemer sinds: {{memberData.startDate}}</p>
    <p class="memberGroupName">Groep: {{memberData.group}}</p>
    <p class="insuredAmount"><a
      [routerLink]="'change-contribution'">Inkomensniveau: {{memberData.coverage | spCurrency}} <span class="pencil"></span></a></p>
    <ng-container *ngIf="memberData?.spLongJoined">
      <p>SharePeople Lang</p>
      <p>Inkomensniveau: {{memberData.spLongCoverage | spCurrency}}</p>
    </ng-container>
    <p class="sicknessPercentage">Ziektepercentage in jouw groep: {{memberData.utilisation}}%</p>
  </div>
</ng-template>

<ng-template #invest>
  <div class="first-part">
    <h4 class="subtitle">Wil je ook lid worden van de SharePeople Cooperatie?</h4>
    <p><a href="https://sharepeople.nl/cooperatie" target="_blank">Klik dan hier</a> voor meer informatie.</p>
    <p><a (click)="openPopup()">Klik vervolgens hier</a> om een account in Eyevestor aan te maken.</p>
  </div>
  <div class="second-part">
    <h4 class="subtitle">Investeringsronde 2023</h4>
  </div>
</ng-template>

<ng-template #moreInfo>
  <h4>{{customContentBlockData?.title}}</h4>
  <p>{{customContentBlockData?.description}}</p>
  <button [sp-button] (click)="gotoCustomContentLink()">Lees meer</button>
</ng-template>
