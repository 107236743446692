<ng-content select="(select)" ></ng-content>
<ng-content select="(input)" ></ng-content>
<ng-content select="(sp-input-wrapper)" ></ng-content>
<ng-content select="(sp-date-select)" ></ng-content>
<ng-content select="(gkt-forms-date-text)" ></ng-content>
<label class="fake-input"></label>
<label [innerHTML]="label"></label>
<!--maybe not sensible to rely on the erorMessage in case the vealaidation eror service fials-->
 <!--consider using the formControl from the select to get the validity state-->
<p *ngIf="errorMessage" class="error">{{errorMessage}}</p>
<p *ngIf="hint" class="hint">{{hint}}</p>
