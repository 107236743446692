<section class="contribution-header">
  <h1>Donaties</h1>
  <sp-shared-lib-rounded-container shadow class="donation-tile-month average-donation" *ngIf="donationHistory?.average !== null">
    <div>
      <p class="amount">&#8364; {{donationHistory.average}}</p>
      <p class="month">Gemiddeld per maand (sinds {{donationHistory.registrationCompletedDate}}) </p>
    </div>
  </sp-shared-lib-rounded-container>
</section>

<section class="all-contributions" *ngIf="donationHistory?.history?.length > 0; else noContributions">
  <div class="section-header">
    <h4>Alle donaties</h4>
  </div>

  <table class="contribution-table">
    <tr *ngFor="let donation of donationHistory?.history">
      <td>
        <h4 class="month">{{donation.period}}</h4>
      </td>
      <td class="amount-column">
        <div class="column-wrapper">
          <h4 *ngIf="donation.amount > 0; else noAmount">&#8364; {{donation.amount}}</h4>
          <ng-template #noAmount>
            <h5></h5>
          </ng-template>
        </div>
      </td>
      <td>
        <p *ngIf="donation.status !== 'Niet gedaan'" class="receiver">{{donation.status == 'Betaald'?'Donatie':''}} {{donation.description}}</p>
        <p *ngIf="donation.status === 'Niet gedaan'" class="not-paid"> {{donation.status}}</p>
      </td>
    </tr>
  </table>
</section>

<ng-template #noContributions>
  <section class="no-contributions">
    <h4>Je hebt nog geen donatie geschiedenis</h4>
  </section>
</ng-template>
