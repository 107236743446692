import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavMenuComponent} from './nav-menu/nav-menu.component';
import { InfoListComponent } from './info-list/info-list.component';
import { InfoListItemComponent } from './info-list/info-list-item/info-list-item.component';
import {RouterModule} from '@angular/router';
import { PageWrapperComponent } from './page-wrapper/page-wrapper.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TextTransformDirective} from './directives/text-transform.directive';
import {SpecialCharTrimmerDirective} from './directives/special-char-trimmer.directive';
import {TcOverlayComponent} from './tc-overlay/tc-overlay.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
// import {ClipboardModule} from 'ngx-clipboard';
// import {SpSharedLibModule} from "../../../../sp-shared-lib/src/lib/sp-shared-lib.module";
import {SpSharedLibModule} from 'sp-shared-lib';
import {ClipboardModule} from '@angular/cdk/clipboard';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SpSharedLibModule,
    MatAutocompleteModule,
    ClipboardModule
  ],
  declarations: [
    NavMenuComponent,
    InfoListComponent,
    InfoListItemComponent,
    PageWrapperComponent,
    TextTransformDirective,
    SpecialCharTrimmerDirective,
    TcOverlayComponent
  ],
  exports: [
    CommonModule,
    NavMenuComponent,
    SpSharedLibModule,
    InfoListComponent,
    InfoListItemComponent,
    FormsModule,
    ReactiveFormsModule,
    PageWrapperComponent,
    TextTransformDirective,
    SpecialCharTrimmerDirective,
    TcOverlayComponent,
    MatAutocompleteModule,
    ClipboardModule
  ]
})
export class SharedModulePortal { }
