import {Component, OnInit} from '@angular/core';
import {MyAreaRestService} from '../my-area.rest.service';
import {RegistrationService} from '../../registration-module/registration.service';
import {BehaviorSubject, EMPTY, Observable, Subject, throwError, timer} from 'rxjs';
import {Router} from '@angular/router';
import {MyAreaService} from '../my-area.service';
import {catchError, retry, share, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {UserProfile} from '../profile/profile.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {environment} from '../../../environments/environment';
import {AOVTermsPopupComponent} from './aov-terms-popup/aov-terms-popup.component';
import {LongWarningPopupComponent} from './long-warning-popup/long-warning-popup.component';

export interface MemberData {
  group: string;
  coverage: number;
  utilisation: string;
  memberCode: string;
  spLongInvitationCode?: string;
  eligibleForSpLong?: boolean;
  spLongCoverage?: number;
  spLongJoined?: boolean;
  spLongRejected?: boolean;
  startDate: string;
  reportedSick: string;
  contribution: {
    contributionAmount: number;
    contributionToken: string;
    payeeName: string;
  };
  aov: {
    eligibleForAovFirstPayment: boolean;
    eligibleForAovSubscription: boolean;
    firstFee: number;
    paymentUrl: string;
    monthlyFee: number;
    subscription?: boolean;
  };
  occupation: {
    risk: number;
    name: string;
  };

  contributionHistory: {
    average: string;
    history: [
      {
        description: string;
        amount: number;
        period: string;
        status: {
          name: string;
          enum: string;
        }
      }
    ],
    hasMissedContributions?: boolean;
  };
  canApplyForAov?: boolean;
  registrationCode?: string;
  dateofbirth?: string;
}

export interface ContentBlockData {
  title: string;
  description: string;
  link: string;
}

@Component({
  selector: 'sp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  yourAccountInfo: MemberData;

  memberData$: BehaviorSubject<MemberData | null> = new BehaviorSubject<MemberData | null>(null);
  profileData$: Observable<UserProfile>;
  customContentBlockData: (ContentBlockData | null) = null;
  eyevestorPopupActive = false;
  popupText = 'Je krijgt een email van Eyevestor om je account te activeren.';
  popupLoading = true;
  aovTermsPopup = false;
  longWarningPopup = false;
  challengeCode: string;
  showCopyAlert = false;
  memberInviteForm: UntypedFormGroup;
  memberInvited = false;
  displayMemberInviteError = false;
  extendedCoverageOptions;
  memberInvitationCode: number;
  spLongInvitationCode: string;
  linkToSpLong: string;
  private stopPolling = new Subject();
  destroy$ = new Subject();

  showRiskModalCutOff=5;
  months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  updatedHistory$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  isRejected = false;

  occupation?: string;
  dob?: string;
  regCode?: string;
  constructor(
    private restService: MyAreaRestService,
    private registrationService: RegistrationService,
    private router: Router,
    private myAreaService: MyAreaService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.createMemberInviteForm();
    this.myAreaService.init();
    this.myAreaService.memberData$.pipe(takeUntil(this.destroy$)).subscribe((memberData: MemberData) => {
      this.regCode = memberData.registrationCode;
      this.occupation = memberData?.occupation?.name;
      this.dob = memberData.dateofbirth;

      const updated = [];
      let lastDate;
      memberData.contributionHistory.history?.forEach((donation)=>{
        // If average object
        if(!donation.period) {
          updated.push(donation);
          return;
        }

        const year = parseInt(donation.period.substr(4));
        const month = this.months.indexOf(donation.period.substr(0,3));
        const date = new Date(year, month);
        let shouldBe = lastDate?new Date(lastDate.setMonth(lastDate.getMonth()-1)):date;
        let count = 0;
        // while donation date is not equal to shouldBe date push "Geen donatie" en bump shouldBe down 1 month
        while (date.getMonth() != shouldBe.getMonth() && count<10) {
          if(shouldBe) {
            updated.push({
              period: this.months[shouldBe.getMonth()] + ' ' + shouldBe.getFullYear(),
              description: 'Geen Donatie',
              amount: '0,00',
              status: 'no-donation'
            });
            shouldBe = new Date(shouldBe.setMonth(shouldBe.getMonth()-1));
            count++;
          }
        }
        // If donation date is equal to shouldBe date, push donation to array and set as last(used)Date
        if(date.getMonth() == shouldBe.getMonth() || !shouldBe) {
          lastDate = date;
          updated.push(donation);
        }
      });

      this.updatedHistory$.next(updated);
      this.memberData$.next(memberData);
    });
    this.profileData$ = this.myAreaService.profile$;
    this.myAreaService.memberData$.pipe(take(1)).subscribe(memberData => {
      this.spLongInvitationCode = memberData.spLongInvitationCode;
      this.linkToSpLong = `${environment.sp_long_url}?code=${this.spLongInvitationCode}`;
    });

    this.getCustomContentBlockData();
    this.generateChallengeCode();
    this.pollMemberData();
    console.warn(environment, environment.sp_long_url);

  }

  ngOnDestroy() {
    this.stopPolling.next();
  }

  pollMemberData() {
    timer(1, 5000).pipe(
      switchMap(() => {return this.myAreaService.pollMemberData();}),
      retry(),
      tap((memberData) => {return this.callStopPolling(memberData);}),
      catchError((origError) :any => {
        console.log(origError);
      // if(origError.status==404) this.callStopPolling
      }),
      share(),
      takeUntil(this.stopPolling)
    ).subscribe((resp) => {
    });
  }

  // iterate = 0;
  callStopPolling(memberData: MemberData) {
    this.memberData$.next(memberData);

    if(memberData.aov && !memberData.aov.eligibleForAovFirstPayment) {
      this.stopPolling.next();
    }
  }


  createMemberInviteForm() {
    this.memberInviteForm = this.formBuilder.group({
      name: [null, [Validators.required, CustomValidators.fullname] ],
      email: [null, [Validators.required, CustomValidators.email]]
    });
  }

  sendMemberInvite(): void {
    if (this.memberInviteForm.valid) {
      const name = this.memberInviteForm.get('name').value.trim();
      const email = this.memberInviteForm.get('email').value;
      this.restService.sendMemberInvite(email, name)
        .pipe(
          catchError((origError) => {
            this.displayMemberInviteError = true;
            this.memberInvited = false;
            return throwError(origError);
          })
        )
        .subscribe(() => {
          this.memberInvited = true;
          this.displayMemberInviteError = false;
          this.memberInviteForm.clearValidators();
          this.memberInviteForm.updateValueAndValidity();
          this.memberInviteForm.reset();
        });
    } else {
      this.memberInvited = false;
      this.memberInviteForm.markAllAsTouched();
    }
  }

  getCustomContentBlockData() {
    this.restService.getCustomContentData()
      .subscribe(
        customData => {
          this.customContentBlockData = customData ? customData : null;
        }
      );
  }

  gotoCustomContentLink() {
    // this.router.navigateByUrl(this.customContentBlockData.link);
    window.open(this.customContentBlockData.link);
  }

  openAOVPayment() {
    this.aovTermsPopup  = true;
  }

  openLongWarning() {
    this.longWarningPopup = true;
  }

  startAovOnboarding() {
    const base_url = `${environment.donation2aov_url}`;
    const url = base_url + '?type=donation2aov&dob=' + this.dob + '&occupation=' + this.occupation + '&registrationCode=' + this.regCode;
    console.log(url);
    window.open(url, '_self');
  }

  openPopup(){
    this.eyevestorPopupActive = true;
    this.myAreaService.postEyevestorSignUp()
      .pipe(
        catchError((origErr) => {
          if (origErr.status === 403) {
            this.popupText = 'Je hebt al een account bij Eyevestor.';
            this.popupLoading = false;
            return EMPTY;
          } else {
            this.popupLoading = false;
            return throwError(origErr);
          }
        })
      )
      .subscribe(() => {
        this.popupLoading = false;
      });
  }

  generateChallengeCode() {
    this.profileData$.subscribe(profileData => {
      const firstName = profileData.firstName;
      const dob = new Date (profileData.dob);
      let day = dob.getDate().toString();
      let month = (dob.getMonth() + 1).toString();
      if (month.length === 1) {
        month = `0${month}`;
      }
      if (day.length === 1) {
        day = `0${day}`;
      }
      this.challengeCode = `${firstName}${day}${month}`.toUpperCase();
    });
    return this.challengeCode;
  }

  navigateTo(url: string) {
    this.router.navigateByUrl('dashboard/' + url);
  }

  triggerCopyAlert() {
    this.showCopyAlert = true;
    setTimeout(() => {return this.showCopyAlert = false;}, 1000);
  }

  getExtendedCoverageOptions() {
    this.myAreaService.getExtendedCoverageOptions().subscribe(options => {
      this.extendedCoverageOptions = options;
    });
  }

  protected readonly AOVTermsPopupComponent = AOVTermsPopupComponent;
  protected readonly LongWarningPopupComponent = LongWarningPopupComponent;
}
